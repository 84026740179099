#btn-toggle-mentions{
    background-color: var(--color-bg);
    position: absolute;
    bottom: 42px;
    left: 5px;
    height: 28px;
    width: 28px;
    display: block;
    z-index: 1;
    border-radius: 50%;
    border-width: 0;
    padding: 0;
}

#div-mentions{
    background-color: var(--color-bg);
    height: 300px;
    width: 200px;
    position: absolute;
    bottom: 75px;
    left: 5px;
    z-index: 1;
    border-radius: 6px;
    padding: 0.1rem 0.4rem;
    font-size: 9px;
}

#copyright {
    position: fixed;
    bottom: 2px;
    right: 2px;
    font-size: 12px;
    font-weight: 500;
}