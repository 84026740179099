@media only screen and (max-width: 1024px) {
    button#btn_2019,
    button#btn_hier,
    button#btn_temps_reel,
    button#btn_1_jour,
    button#btn_3_jours,
    button#btn_7_jours{
        padding: 0 0.625rem !important;
    }
}

button#btn_2019,
button#btn_hier,
button#btn_temps_reel,
button#btn_1_jour,
button#btn_3_jours,
button#btn_7_jours{
    width: auto;
    height: 2rem;
    background-color: var(--color-green);
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 6px;
    padding: 0 1.25rem;
    margin: 0.3125rem;
    font-size: 0.75rem;
    color: white;
}

button#btn_2019:disabled,
button#btn_hier:disabled,
button#btn_temps_reel:disabled,
button#btn_1_jour:disabled,
button#btn_3_jours:disabled,
button#btn_7_jours:disabled {
    background-color: var(--color-disabled);
}