/*Block stats*/
.block_stats:first-child {
    border-left: none;
}

.block_stats {
    color: var(--color-bg);
    background-color: var(--color-bg-stats);
    width: 140px;
    height: 100px;
    display: flex;
    padding: 1.25rem 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-left: 1px solid var(--color-seperator);
    border-right: 1px solid var(--color-seperator);
    cursor: pointer;
}

.block_stats_graph {
    display: flex;
    justify-content: center;
    /* height: 100vh; */
    width: 100vw;
    /* position: absolute; */
    left: 0;
    visibility: visible;
}

.block_stats:last-child {
    border-right: none;
}

/* .block_stats:hover .block_stats_graph {
    visibility: visible;
    opacity: 1;
} */

/*Block stats values*/
#statsStreet,
#statsSacs,
#statsIsole,
#statsSsInonde,
#statsPremInonde,
#statsTotalInonde,
#statsNbEvac {
    font-family: var(--font-nunito);
    font-size: 2.25rem;
    font-weight: normal;
}

/*#statsIsole {*/
/*    color: var(--color-yellow);*/
/*}*/

/*#statsSsInonde {*/
/*    color: var(--color-yellow);*/
/*}*/

/*#statsPremInonde {*/
/*    color: var(--color-red);*/
/*}*/

/*#statsTotalInonde {*/
/*    color: var(--color-red);*/
/*}*/

/*#statsNbEvac {*/
/*    color: var(--color-red);*/
/*}*/

.statsIsoleDescription {
    margin: 0 !important;
    padding: 0 !important;
    font-size: 0.75rem !important;
    line-height: 0.75rem !important;
}

@media only screen and (max-width: 1024px) {
    #statsStreet,
    #statsSacs,
    #statsIsole,
    #statsSsInonde,
    #statsPremInonde,
    #statsTotalInonde,
    #statsNbEvac {
        font-size: 1.25rem;
    }

    .statsIsoleDescription {
        font-size: 0.25rem !important;
        line-height: 0.25rem !important;
    }

    .block_stats {
        width: 33.3333%;
        height: 80px;
    }

    .block_stats:last-child {
        border-right: 1px solid var(--color-seperator);
    }
}

@media only screen and (max-width: 600px) {
    .block_stats {
        height: 60px;
    }

    .statsIsoleDescription {
        font-size: 6px !important;
        line-height: 6px !important;
    }
}