#btn-toggle-legende {
    background-color: var(--color-bg);
    position: absolute;
    top: 150px;
    left: 15px;
    height: 32px;
    width: 32px;
    display: block;
    z-index: 1;
    border-width: 0;
    padding: 0;
}

#div-legende {
    background-color: var(--color-bg);
    height: 290px;
    width: 310px;
    position: absolute;
    top: 190px;
    left: 15px;
    z-index: 1;
    border-radius: 6px;
    padding: 0.1rem 0.4rem;
    font-size: 9px;
}

@media only screen and (max-width: 1024px) {
    #btn-toggle-legende {
        position: absolute;
        top: 283px;
        left: 15px;
    }
}